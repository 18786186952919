import React, { Component } from "react"
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
// import { navigate } from "gatsby"

// FINANCIERA COMPONENTS
import { useTheme } from '@findep/microfronts-core'
import { CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'
import { withThemeProps } from "@findep/microfronts-core"

//MATERIAL UI COMPONENTS
import { Grid, Container, Backdrop, Snackbar, Box,Typography } from "@material-ui/core"
import { Alert } from '@material-ui/lab';

//ICONS MATERIAL UI
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';

//SERVICIOS 
import { CataloguesService } from '../../services/LandingV4'
import { restoreFromSessionStorage } from "../../components/pages/afi/restoreSessionStorage"
import { iconStyle, h1Style, root } from '../../styles/lo-sentimos'


function JumioContent( props ) {
  const { palette } = useTheme()
  const { messageIdentificacion, messageVideo } = props

  const Rounded = css`
   background: #476013;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em;   
  `
  const cntTxtTwo = css`  
    width: 224px;  
    margin: 20px 20px 19px 16px;
    font-weight: normal;  
    color: #476013;
  `

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={1}>
          <p css={Rounded}>1</p>
        </Grid>
        <Grid item  >
          <p css={cntTxtTwo}>
            {messageIdentificacion}
          </p>

        </Grid>
      </Grid>

      <Grid container direction="row" spacing={4} >
        <Grid item xs={1}>
          <p css={Rounded}>2</p>
        </Grid>
        <Grid item >
          <p css={cntTxtTwo}>
            {messageVideo}
          </p>

        </Grid>
      </Grid>
    </div>
  )
}

const sizeGrl = css`  
   font-size: 16px;
    `
const cntTxtOne = css`  
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        line-height: 1.5;
        letter-spacing: 0.15px;
    `
const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `
class JumioVerifiy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flujo: restoreFromSessionStorage('flujo'),
      habilita: false,
      bdHabilita: false,
      errorService: false
    }
  }

  async fetchJumio() {

    const flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    let id = flujo?.creditApplicationId

    this.setState({ habilita: true, bdHabilita: true })
    const catalogues = new CataloguesService();
    catalogues.identityBiometrics(id)
      .then((response) => {
        let urlJumio = response.data.verificationUrl
        window.location = urlJumio

      })
      .catch(error => {
        this.setState({ errorService: true, habilita: false, bdHabilita: false })
      })
  }



  render() {

    const {
      titleIconPage,
      pMessage,
      jumioContentIdentificacion,
      jumioContentVideo,
      buttonDegradegText,
      titleIconRecuerda,
      boxSubtitle,
      alertMessage,
    } = this.props

    return (
      <Container maxWidth="sm" justify="center" css={sizeGrl}>

        <Grid container justify="center">
          <Grid item style={{ textAlign: "center" }}>
            <TitleIcon icon={SwitchVideoIcon} title={titleIconPage} size={20} textColor />
          </Grid>
        </Grid>
        <br />
        <CardElevation>
          <Grid container>
            <Grid item  >
              <p css={cntTxtOne}>
                {pMessage}
              </p>
            </Grid>
          </Grid>
          <div>
            <JumioContent messageIdentificacion={jumioContentIdentificacion} messageVideo={jumioContentVideo} />
            <br />
            <Grid container spacing={4} justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <ButtonDegrade id="video-verificacion-jumio-button" textButton={buttonDegradegText} onClick={() => this.fetchJumio()} disabled={this.state.habilita} icon={CameraEnhanceIcon} />
              </Grid>
            </Grid>
          </div>

        </CardElevation>
        <br />
        <CardElevation>
          <Grid container justify="center">
            <Grid item style={{ textAlign: "center" }}>
              <div css={root}>
                  <WarningIcon css={iconStyle} />
                  <Typography css={h1Style} component='span'>
                      {titleIconRecuerda}
                  </Typography>      
              </div>
              {/* <TitleIcon icon={WarningIcon} title={titleIconRecuerda} size={20} textColor /> */}
              <br />
            </Grid>
            <br />
            {boxSubtitle}           
          </Grid>
        </CardElevation>
        <br />
        <Backdrop css={bDrop} open={this.state.bdHabilita} >
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            {alertMessage}
          </Alert>
        </Snackbar>

        &nbsp;

      </Container>


    )
  }
}

export default withThemeProps(JumioVerifiy, 'VDPNJumioVerifica')


JumioVerifiy.propTypes = {
  titleIconPage: PropTypes.string,
  pMessage: PropTypes.string,
  jumioContentIdentificacion: PropTypes.string,
  jumioContentVideo: PropTypes.string,
  buttonDegradegText: PropTypes.string,
  titleIconRecuerda: PropTypes.string,
  boxSubtitle: PropTypes.any,
  alertMessage: PropTypes.string
}

JumioVerifiy.defaultProps = {
  titleIconPage: "Video verificación",
  pMessage: "A través de los siguientes pasos, validaremos tu indentidad:",
  jumioContentIdentificacion: "Se te pedirá foto de tu indentificación oficial por ambos lados.",
  jumioContentVideo: "Se tomará un video en el cual deberás mover tu rostro adelante y atrás.",
  buttonDegradegText: "IR A VIDEO-VERIFICACIÓN",
  titleIconRecuerda: "Recuerda",
  boxSubtitle: <Box textAlign="center">
    Haz clic <a href="https://drive.google.com/file/d/1GfHzRGKYOKRLWNP1qsmMQpvUtF0oflxG/view?usp=sharing"  target="_blank" rel="nofollow noopener noreferrer">aquí</a> para ver un video que explica paso a paso el proceso de video verificación.<br />
    Recuerda que para continuar necesitas habilitar los permisos de tu cámara y estar en un lugar iluminado.
  </Box>,
  alertMessage: "Lo sentimos, por el momento el servicio no se encuentra disponible"
}
